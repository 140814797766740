export function validatePassword(password: string): void {
  if (password && (password.trim().length <= 5 || password.trim().length >= 55))
    throw new Error('Senha invalida, certifique-se de enviar uma senha com mais de 5 caracteres e menos de 55');

  const upperCaseRegex = new RegExp(('[A-Z]'));
  const hasUppercaseLowercase = upperCaseRegex.test(password);

  if (!hasUppercaseLowercase)
    throw new Error('A senha deve possuir ao menos uma letra maiuscula');

  const lettersRegex = new RegExp('[a-z]');
  const hasLetters = lettersRegex.test(password);

  if (!hasLetters)
    throw new Error('A senha deve possuir ao menos uma letra minuscula');

  const numberRegex = new RegExp(('^(?=.*[0-9]).{1,}$'));
  const hasNumbers = numberRegex.test(password);

  if (!hasNumbers)
    throw new Error('A senha deve possuir ao menos um número');

  const specialCharactersRegex = new RegExp('(?=.*[!@#$&*]).{1,}');
  const hasSpecialCharacters = specialCharactersRegex.test(password);

  if (!hasSpecialCharacters)
    throw new Error('A senha deve possuir ao menos um caractere especial');
}
