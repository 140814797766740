import { CancelToken } from 'axios';
import { GetState, SetState } from 'zustand';

import { environment } from '../../environments/environment';
import { LoginPayload } from '../../models/payloads/login.payload';
import { TokenProxy } from '../../models/proxies/token.proxy';
import { UserProxy } from '../../models/proxies/user.proxy';
import api from '../../services/api';
import { validateCPF } from '../../utils/cpf-validator';
import { validateName } from '../../utils/name-validator';
import { validatePassword } from '../../utils/password-validator';
import { UseAuthStore } from './models';
import { SelectClassPayload } from '../../models/payloads/select-class.payload';
import { UpdateUserPayload } from '../../models/payloads/update-user.payload';
import { defaultQueryClient } from '../../App';
import { CreateUserPayload } from '../../models/payloads/create-user.payload';

const clearUserInformation = {
  isLogged: false,
  jwt: null,
  user: null,
};

export function getTokenProxyFromAPI(set: SetState<UseAuthStore>): UseAuthStore['getTokenProxyFromAPI'] {
  return async function (cancelToken: CancelToken, authPath: string, payload: LoginPayload) {
    try {
      validateCPF(payload.username);

      const url = environment.api.auth.local.replace('{authType}', authPath);
      const { data: jwt } = await api.post<TokenProxy>(url, payload, { cancelToken });

      set({ jwt });
    } catch (error) {
      if (error.__CANCEL__)
        return;

      if (error.response?.data?.message[0])
        throw new Error(error.response?.data?.message);

      throw new Error(error.message || 'Seu e-mail ou senha não estão corretos.');
    }
  };
}

export function onOAuth2(set: SetState<UseAuthStore>): UseAuthStore['onOAuth2'] {
  return async function (code: string) {
    try {
      const { data: jwt } = await api.get<TokenProxy>(environment.api.oauth2.callback.replace('{code}', code));

      set({ jwt });

    } catch (error) {
      if (error.__CANCEL__)
        return;

      if (error.response?.data?.message[0])
        throw new Error(error.response?.data?.message);

      throw new Error('Erro de autenticação com OAuth2.');
    }
  };
}

export function getInfoAboutCurrentUserFromAPI(
  set: SetState<UseAuthStore>,
): UseAuthStore['getInfoAboutCurrentUserFromAPI'] {
  return async function (cancelToken) {
    try {
      const { data: user } = await api.get<UserProxy>(environment.api.user.me, { cancelToken });

      set({ user, isLogged: true });
    } catch (error) {
      resetState(set, false);

      if (error.__CANCEL__)
        return;

      if (error.response?.data?.message[0])
        throw new Error(error.response?.data?.message);

      throw new Error('Não foi possível obter as informações do seu usuário, por favor, tente novamente.');
    }
  };
}

export function createUserFromAPI(
  set: SetState<UseAuthStore>,
): UseAuthStore['createUserFromAPI'] {
  return async function (cancelToken: CancelToken, payload: CreateUserPayload) {
    try {
      validateName(payload.name);
      validateCPF(payload.username);
      validatePassword(payload.password);

      const { data } = await api.post<UserProxy>(environment.api.user.create, payload, { cancelToken });

      return data;
    } catch (error) {
      resetState(set, false);

      if (error.__CANCEL__)
        return;

      if (error.response?.data?.message[0])
        throw new Error(error.response?.data?.message);

      throw new Error(error.message || 'Não foi possível realizar a criação do usuário, por favor, tente novamente.');
    }
  };
}

export function selectClassFromAPI(set: SetState<UseAuthStore>, get: GetState<UseAuthStore>): UseAuthStore['selectClassFromAPI'] {
  return async function (cancelToken: CancelToken, payload: SelectClassPayload) {
    try {
      const user = get().user;
      const url = environment.api.user.selectClass.replace('{userId}', String(user.id));

      await api.put<void>(url, payload, { cancelToken });

      set({ user: { ...user, classType: payload.classType } });
    } catch (error) {
      if (error.__CANCEL__)
        return;

      throw new Error(error.response?.data?.message || 'Não foi possível atualizar a sua classe.');
    }
  };
}

export function updateUserFromAPI(set: SetState<UseAuthStore>, get: GetState<UseAuthStore>): UseAuthStore['updateUserFromAPI'] {
  return async function (cancelToken: CancelToken, payload: UpdateUserPayload) {
    try {
      const user = get().user;
      const url = environment.api.user.update.replace('{userId}', String(user.id));

      validateName(payload.name);

      const { data: userUpdated } = await api.put<UserProxy | undefined>(url, payload, { cancelToken });

      set({ user: userUpdated });

      return userUpdated;
    } catch (error) {
      if (error.__CANCEL__)
        return;

      if (error.response?.data?.message[0])
        throw new Error(error.response?.data?.message);

      throw new Error(error.message || 'Não foi possível atualizar a sua classe.');
    }
  };
}

export function resetState(set: SetState<UseAuthStore>, isLogoutUser: boolean = true) {
  if (isLogoutUser) {
    return function () {
      defaultQueryClient.clear();
  
      set(clearUserInformation);
    }; 
  }

  () => {
    defaultQueryClient.clear();
  
    set(clearUserInformation);
  }
}
